import { PaperClipIcon } from '@heroicons/react/20/solid'
import { getQueryParamValue } from '../../../utils/general'
import mapDataToCards, { mediaMap } from '../../../utils/artDetailsUtils'
import { getValue, renderValue } from '../../../components/cardDetails/valueRender'
import { useLayoutEffect, useRef, useState } from 'react'
import useDivHeights from '../../../utils/useDivHeights'
import { isPlayerOrLNProduct, LNTransferDetailsGenerator } from '../../../utils/LNTransferDetailsGenerator'
import LNDetails from './lnDetails'
import RosterGrid from './rosterGrid'

// Styles
import styles from './info.module.css'

interface Attachment {
  name: string;
  size: string;
  downloadLink: string;
}

export interface InfoProps {
  about?: string;
  attachments?: Attachment[];
  isLNTransfer: boolean;
  isEasyView: boolean;
  originalDocId?: string;
  productType: string;
  fullWizardData?: any;
  [key: string]: any;
}

const AttachmentList = ({ attachments }: { attachments: Attachment[] }) => (
  <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
    {attachments.map((attachment, index) => (
      <li key={index} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
        <div className="flex w-0 flex-1 items-center">
          <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <div className="ml-4 flex min-w-0 flex-1 gap-2">
            <span className="truncate font-medium">{attachment.name}</span>
            <span className="flex-shrink-0 text-gray-400">{attachment.size}</span>
          </div>
        </div>
        <div className="ml-4 flex-shrink-0">
          <a href={attachment.downloadLink} className="font-medium text-indigo-600 hover:text-indigo-500">
            Download
          </a>
        </div>
      </li>
    ))}
  </ul>
);

export default function Info({ attachments, about, isLNTransfer, isEasyView, isWizardDesign, originalDocId, productType, fullWizardData, ...restProps }: InfoProps) {
  const showLNTrasnferDetails = isLNTransfer && Object.keys(LNTransferDetailsGenerator).includes(productType)
  const displayRoster = showLNTrasnferDetails && isPlayerOrLNProduct(productType);
  const artIdParam = getQueryParamValue("artId");
  const currentUrl = new URL(window.location.href);
  const originalDocIsDifferent = originalDocId !== artIdParam;
  const artNotes: string = restProps['artNotes'];
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const artNotesRef = useRef<HTMLDivElement>(null);
  const [artNotesHeight, setArtNotesHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (artNotesRef.current) {
      const spanHeight = artNotesRef.current.scrollHeight;
      setArtNotesHeight(spanHeight);
    }
  }, [artNotes])

  if (originalDocId && originalDocIsDifferent) {
    const paramsToRemove = ['DocNo', 'DocLineNo', 'Page'];
    paramsToRemove.forEach(param => currentUrl.searchParams.delete(param));
    currentUrl.searchParams.set('artId', originalDocId);
  }

  restProps = Object.assign(restProps.media ? { mediaText: mediaMap[restProps.media as keyof typeof mediaMap] ?? "" } : {}, restProps);
  let mappedCards = mapDataToCards(isLNTransfer, isEasyView);

  // Only keep the mapped card if it's going to show data
  mappedCards = mappedCards.filter((cardConfig) => {
    return cardConfig.properties.some((property) => {
      const value = getValue(property, restProps, artIdParam);
      const displayValue = renderValue(value);
      return displayValue !== null;
    });
  });

  const { refs, heights } = useDivHeights(mappedCards?.length);
  const maxHeight = Math.max(...heights);

  function checkMappedCards() {
    if (showLNTrasnferDetails) {
      const cTotal = (showLNTrasnferDetails && displayRoster ? 2 : (showLNTrasnferDetails ? 1 : 0)) + (artNotes ? 1 : 0);
      return cTotal === 1 ? styles.oneColumn : (cTotal === 2 ? styles.twoColumns : styles.threeColumns);
    }

    const totalColumns = (mappedCards?.length ?? 0) + (artNotes ? 1 : 0);
    const columnStyles: Record<number, string> = {
      5: styles.fiveColumns,
      4: styles.fourColumns,
      3: styles.threeColumns,
      2: styles.twoColumns,
      1: styles.oneColumn,
    };

    return columnStyles[totalColumns] ?? styles.oneColumn;
  }

  return (
    <>
      <div className={styles.infoWrapper}>
        {/* L&N Design Details */}
        {showLNTrasnferDetails && (
          <LNDetails
            productType={productType}
            fullWizardData={fullWizardData}
            checkMappedCards={checkMappedCards} />
        )}

        {!showLNTrasnferDetails && mappedCards.map((cardConfig, index) => (
          <div key={index} className={[styles.card, checkMappedCards()].join(' ')}>
            <h3 className={styles.title}>{cardConfig.title}</h3>
            <div className={styles.cardBody}
              ref={el => (refs.current[index] = el!)}>
              {originalDocId && index == 0 && originalDocIsDifferent && (
                <div className={styles.details} key={"originalDocId"}>
                  <span className={styles.name}>Original Doc:</span>
                  <span className={styles.value}>
                    <a href={currentUrl.toString()} className="font-medium text-indigo-600 hover:text-indigo-500">
                      View Original Document
                    </a>
                  </span>
                </div>
              )}
              {cardConfig.properties.map((property) => {
                const value = getValue(property, restProps, artIdParam);
                const displayValue = renderValue(value);

                if (displayValue === null) return null;

                return (
                  <div className={styles.details} key={property.key}>
                    <span className={styles.name}>{property.label}:</span>
                    <span className={styles.value}>{displayValue}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        {/* Roster Grid */}
        {displayRoster &&
          <div className={[styles.card, checkMappedCards(), showLNTrasnferDetails ? styles.bgWhite : ""].join(' ')}>
            <RosterGrid wizardData={restProps} />
          </div>
        }

        {/* Art Notes Column */}
        {artNotes && (
          <div className={[styles.card, checkMappedCards(), showLNTrasnferDetails ? styles.bgWhite : ""].join(' ')}>
            <h3 className={styles.title}>ART NOTES</h3>
            <div
              style={{ maxHeight: !isExpanded ? maxHeight : "" }}
              className={`${styles.artNotesContainer} ${isExpanded ? styles.artNotesExpanded : ''}`}>
              <div className={styles.details} ref={artNotesRef}>
                <span
                  className={styles.value}>{artNotes}
                </span>
              </div>
            </div>
            {(artNotesHeight > maxHeight) &&
              <div onClick={toggleExpand} className={styles.artNotesToggleButton}>
                <span>{isExpanded ? '...view less' : '...view more'}</span>
              </div>
            }
          </div>
        )}
      </div>
      <div>
        {attachments && attachments.length > 0 && (
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <AttachmentList attachments={attachments} />
            </dd>
          </div>
        )}
      </div>
    </>
  );
}