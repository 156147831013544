import React from 'react';

interface WizardData {
  txtName?: string[];
  txtNumber?: string[];
  txtQTY?: number[];
  hOrderType?: string;
  hCharacter?: string;
  qty?: string;
  sku?: string;
  [key: string]: any;
}

interface RosterProps {
  wizardData: WizardData;
  isPrintView?: boolean;
}

interface RowData {
  name?: string;
  number?: string;
  label?: string;
  quantity: number;
}

const processRosterData = (
  wizardData: WizardData
): { rows: RowData[]; headers: string[]; title: string } => {
  // Case 1 - sku and qty
  if (wizardData.qty && wizardData.sku && !wizardData.hOrderType) {
    const quantities = wizardData.qty.split(';').map((q) => parseInt(q, 10));
    const skus = wizardData.sku.split(';');
    const lastCharacters = skus.map((sku) => sku.slice(-1));

    // Check if they are letters or numbers
    const areLetters = lastCharacters.every((char) => /^[A-Za-z]$/.test(char));
    const areNumbers = lastCharacters.every((char) => /^\d$/.test(char));

    const labelHeader = areLetters
      ? 'Letters'
      : 'Numbers'

    if (areLetters || areNumbers) {
      const mappedRows = skus.map((sku, index) => ({
        label: sku.slice(-1),
        quantity: quantities[index] || 0,
      }));

      return {
        rows: mappedRows,
        headers: [labelHeader, 'Quantity'],
        title: 'Pack Contents',
      };
    }
  }

  // Case 2 - hOrderType, hCharacter and qty
  if (wizardData?.hOrderType && wizardData?.hOrderType != "greek"
    && wizardData?.hCharacter && wizardData?.qty) {
    const characters = wizardData.hCharacter.split(';');
    const quantities = wizardData.qty.split(';').map((q) => parseInt(q, 10));

    const filteredRows = characters
      .map((character, index) => ({
        label: character.trim(),
        quantity: quantities[index] || 0,
      }))
      .filter((row) => row.label.length === 1);

    return {
      rows: filteredRows,
      headers: [wizardData.hOrderType, 'Quantity'],
      title: 'Pack Contents',
    };
  }


  // case 3 - Greek
  if (wizardData?.hOrderType && wizardData?.hOrderType == "greek") {
    const greekLetters = [
      "ALPHA", "BETA", "GAMMA", "DELTA", "EPSILON", "ZETA", "ETA", "THETA",
      "IOTA", "KAPPA", "LAMBDA", "MU", "NU", "XI", "OMICRON", "PI", "RHO",
      "SIGMA", "TAU", "UPSILON", "PHI", "CHI", "PSI", "OMEGA"
    ];
    const greekLettersMap: Record<string, string> = {};

    for (let index = 0; index < greekLetters.length; index++) {
      greekLettersMap[`${19 + index}`] = greekLetters[index]
    }

    const characters = wizardData.hCharacter?.split(';').filter(Boolean) ?? [];
    const quantities = wizardData.qty?.split(';').map(q => parseInt(q, 10));

    const greekRows = characters?.map((character, index) => ({
      name: greekLettersMap[character.trim()],
      quantity: quantities?.[index] || 0,
    }));

    return {
      rows: greekRows,
      headers: ["Greek", "Quantity"],
      title: "Pack Contents",
    };
  }

  // Case 4 - txtName, txtNumber, txtQTY
  const { txtName, txtNumber, txtQTY } = wizardData;
  const rows: RowData[] = [];

  if (txtQTY) {
    for (let i = 0; i < txtQTY.length; i++) {
      rows.push({
        name: txtName?.[i],
        number: txtNumber?.[i],
        quantity: txtQTY[i],
      });
    }
  }

  const headers = [
    txtName ? 'Name' : null,
    txtNumber ? 'Number' : null,
    'Quantity',
  ].filter(Boolean) as string[];

  return { rows, headers, title: 'Name and/or Number List' };
};

const RosterGrid: React.FC<RosterProps> = ({ wizardData, isPrintView = false }) => {
  const { rows, headers, title } = processRosterData(wizardData);

  return (
    <>
      <div className={`flex flex-col ${isPrintView && "text-sm"}`}>
        <h3 className={`uppercase text-[#F36E29] font-bold ${!isPrintView ? 'pt-[1.5em] pb-0 px-[1.5em]' : ''}`}>{title}</h3>
        <div className={`bg-white w-full pt-6 ${!isPrintView ? 'px-5' : ''}`}>
          <table className="table-auto w-full">
            <thead>
              <tr className="bg-[#f2f2f2] border-b-[rgb(218,218,218)] border-b border-solid rounded-xl">
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'} text-left text-[black] font-medium capitalize`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  key={index}
                  className="border-b-[rgb(218,218,218)] border-b border-solid px-0 py-[0.5em] font-semibold text-[gray]"
                >
                  {row.name && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.name}</td>}
                  {row.number && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.number}</td>}
                  {row.label && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.label}</td>}
                  <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RosterGrid;